import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Autosuggest, Label } from 'folio-common-components';
import { waitResolve } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  type CadasterHit as CadasterHit,
  type CadasterResult,
  fetchCadasterData,
} from './fetching';

function useInterval(interval = 500) {
  const timerId = React.useRef(0);
  const [elapsed, setElapsed] = React.useState(0);

  React.useEffect(() => {
    window.clearInterval(timerId.current);
    timerId.current = window.setInterval(() => {
      setElapsed(p => p + interval);
    }, interval);

    return () => {
      window.clearInterval(timerId.current);
    };
  }, [interval]);

  const reset = React.useCallback(() => {
    setElapsed(0);
  }, [setElapsed]);

  return [elapsed, reset] as const;
}

/**
 * Specification (not implemented as of 2024-02-20).
 * Figma: https://www.figma.com/file/cxCyOvmr1M6kil6wiDMRHr/SUS---digital-etablering-%F0%9F%9A%80?type=design&node-id=758-2893&mode=design&t=Zyge1A0ZMzY5UC2F-4
 *
 * - When the user focuses the input, nothing happens except focus styling.
 * - When the user has entered 1 or more characters:
 *     - If the input is too short to perform a search, show an autocomplete
 *       entry with no contents, to indicate that there will be suggestions.
 *     - If the input is long enough, trigger a search. Keep the placeholder.
 *       visible. If the search takes more than 150 ms, show the loading spinner
 *       in the input.
 *     - If the search returns hits, show them, if no hits, keep showing the
 *       placeholder autosuggest.
 * - When showing suggestions:
 *     - If the user deletes characters, show the placeholder suggestion
 *     - If the user types more characters:
 *         - Keep the previous suggestions showing
 *         - Start the search
 *         - Show the loading spinner (without delay?)
 *         - If the search returns results, show them
 *         - If it doesn't, show the placeholder
 */

export interface Props {
  label: React.ReactNode;
  icon?: React.ReactNode;
  /**
   * What was there when the page loaded, if there was any stored address info
   * for the founding already.
   */
  initialAddress: string;

  hasValidCadasterAddress: boolean;

  /**
   * Invoked whenever a user selects a hit, or makes a change that voids the
   * previously selected hit.
   * If there was a selection, it is passed as the argument, otherwise, the
   * selected value is undefined
   */
  onSelectionChange: (selected: CadasterHit | undefined) => void;
}

/**
 * Any option that can be displayed in the autosuggest. The placeholders are
 * just meant to be something to nudge the user, not to be activated.
 */
type CadasterOption =
  | { kind: 'hit'; value: CadasterHit }
  | { kind: 'placeholder'; value: string };

function getAddressStringFromHit(
  opt: Extract<CadasterOption, { kind: 'hit' }>,
) {
  return `${opt.value.addressText}, ${opt.value.postalCode} ${opt.value.postalPlace}`;
}

const ItemWrapper = styled.div`
  font-size: 0.8em;
`;

const Suggestion: React.FC<{ hit: CadasterHit }> = props => {
  const { addressText, postalCode, postalPlace } = props.hit;
  return (
    <div>
      <div>
        <b>{addressText}</b>
      </div>
      <ItemWrapper>
        {postalCode} {postalPlace}
      </ItemWrapper>
    </div>
  );
};

// Unique symbol we use to detect if a Promise.race value is due to a timeout
// or some other value.
const timeoutSym = Symbol();

export const CadasterEnabledAddress: React.FC<Props> = props => {
  const {
    initialAddress: address,
    icon,
    label,
    onSelectionChange,
    hasValidCadasterAddress,
  } = props;

  const inputId = React.useId();

  // Tracks the text in the <input> element
  const [inputValue, setInputValue] = React.useState(address);

  // Tracks the text that was typed in the <input>, ignoring anything that
  // was set as the result of arrowing/hovering a result
  const [typedInputValue, setTypedInputValue] = React.useState(address);

  // Tracks if the user has explicitly selected something from the suggestions.
  const [hasSelection, setHasSelection] = React.useState(
    hasValidCadasterAddress,
  );

  // Ref to an array of previously successful searches.
  const searchResultCache = React.useRef<CadasterResult[]>([]);

  // Tracks the result of performing a search for a term
  const [searchResult, setSearchResult] = React.useState<CadasterResult>();

  // Tracks if the component should be displaying a loading spinner.
  // This is not 1:1 correlated with there being an active request, since
  // we might delay displaying it to avoid visual flicker on fast searches.
  const [loading, setLoading] = React.useState(false);

  // Tracks how much time has passed since the last keypress
  const [elapsedTimeFromKeypress, resetElapsedTimeFromKeypress] = useInterval();

  // Tracks if the user has been selecting an item by arrowing up and down.
  // If the user did that, then we should use the thing they arrowed to if
  // they blur. Which will usually be `tab` in that situation.
  const [shouldSetSelectionOnBlur, setShouldSetSelectionOnBlur] =
    React.useState(false);

  /**
   * Callback to perform a search for a term. Does not trigger until input has
   * been idle for 300ms.
   */
  const fetcher = useDebouncedCallback(async (term: string) => {
    const cadasterPromise = fetchCadasterData(term);
    const res = await Promise.race([
      cadasterPromise,
      waitResolve(timeoutSym, 150),
    ]);

    // If there is no result within 150 ms, show loader
    if (res === timeoutSym) {
      setLoading(true);
    }

    const result = await cadasterPromise;
    if (result.error == null) {
      searchResultCache.current.push(result);
    }
    setSearchResult(result);
    setLoading(false);
  }, 300);

  let suggestions: CadasterOption[];

  if (hasSelection) {
    // User has activated a selection, so no suggestion should be shown
    suggestions = [];
  } else if (inputValue.length === 0) {
    // Empty input, show nothing
    suggestions = [];
  } else if (inputValue.length <= 3 && elapsedTimeFromKeypress > 2000) {
    // There are three or less characters, and the user is "slow". Nudge
    // them, so they know we need more text for the search.
    suggestions = [{ kind: 'placeholder', value: 'Skriv mer' }];
  } else if (inputValue.length <= 3) {
    // Too short input to try to do a search. Show placeholder
    suggestions = [{ kind: 'placeholder', value: ' ' }];
  } else {
    // There's enough input, so there might be a search result
    if (searchResult) {
      // Yes, there is a search result
      if (searchResult.hits.length === 0) {
        // But with no hits
        suggestions = [{ kind: 'placeholder', value: ' ' }];
      } else {
        // And there are hits
        suggestions = searchResult.hits.map(e => ({ kind: 'hit', value: e }));
      }
    } else {
      // Enough input, but no search result
      suggestions = [{ kind: 'placeholder', value: ' ' }];
    }
  }

  return (
    <>
      <Label for={inputId}>{label}</Label>
      <Autosuggest<CadasterOption>
        icon={icon}
        inputProps={{
          value: inputValue,
          id: inputId,
          autoComplete: 'off',
          spellCheck: false,
          onBlur: (_, extra) => {
            const suggestion = extra?.highlightedSuggestion;
            if (shouldSetSelectionOnBlur && suggestion?.kind === 'hit') {
              // the user has arrowed down to an item, and then blurred. We
              // assume their intention was to use the thing they arrowed to.
              setHasSelection(true);
              setTypedInputValue(getAddressStringFromHit(suggestion));
              onSelectionChange(suggestion.value);
            }
          },
          onFocus: () => {
            resetElapsedTimeFromKeypress();
          },
          onChange: (_, { newValue, method }) => {
            if (method === 'escape') {
              setInputValue(typedInputValue);
              return;
            }

            setInputValue(newValue);

            if (method === 'down' || method === 'up') {
              setShouldSetSelectionOnBlur(true);
            } else {
              setShouldSetSelectionOnBlur(false);
            }

            if (method === 'type') {
              // It changed because someone typed a key, so we track the value
              // so we can reset to it if editing is canceled
              setTypedInputValue(newValue);
            }

            if (
              (inputValue.length > 3 && newValue.length <= 3) ||
              inputValue.length === 0
            ) {
              // We reset the counter only if you go from an empty input to a
              // non-empty input, OR, if you go from more than 3 to fewer
              // characters in the input.
              resetElapsedTimeFromKeypress();
            }
            if (hasSelection) {
              // Tell parent that the selection they may know about is void.
              onSelectionChange(undefined);
            }
            setHasSelection(false);
          },
          onKeyDown: event => {
            // If we press Enter, and there's no selected option, and there's exactly one hit, use it
            if (
              event.key === 'Enter' &&
              !hasSelection &&
              searchResult?.hits.length === 1
            ) {
              const [hit] = searchResult.hits;
              setHasSelection(true);
              setInputValue(
                getAddressStringFromHit({ kind: 'hit', value: hit }),
              );
              onSelectionChange(hit);
              event.stopPropagation();
            }
          },
        }}
        renderSuggestionsContainer={({ containerProps, children, query }) => {
          if (!query) {
            return null;
          }
          return (
            <div
              {...containerProps}
              css={css`
                max-height: 400px !important;
              `}
            >
              {children}
            </div>
          );
        }}
        renderSuggestion={item => {
          if (item.kind === 'hit') {
            return <Suggestion hit={item.value} />;
          } else {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                onClick={event => event.stopPropagation()}
                aria-disabled={true}
                css={css`
                  color: ${colors.wcagGray};
                `}
              >
                <ItemWrapper>{item.value}</ItemWrapper>
              </div>
            );
          }
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => {
          fetcher.cancel();
          if (hasSelection) {
            // User has explicitly clicked an address. Don't search for it
            return;
          } else if (value.length <= 3) {
            // The input is too short for a meaningful search
            return;
          }

          const cachedValue = searchResultCache.current.find(
            e => e.term === value,
          );
          if (cachedValue) {
            setSearchResult(cachedValue);
          } else {
            fetcher(value);
          }
        }}
        getSuggestionValue={e => {
          if (e.kind === 'hit') {
            return getAddressStringFromHit(e);
          } else {
            return inputValue;
          }
        }}
        onSuggestionSelected={(_, { suggestion }) => {
          // Tell the parent that user actively selected a suggestion, if it
          // was not a placeholder
          if (suggestion.kind === 'hit') {
            setHasSelection(true);
            setTypedInputValue(getAddressStringFromHit(suggestion));
            onSelectionChange(suggestion.value);
          }
        }}
        onSuggestionsClearRequested={() => {
          // User blurred input or selected an item
          setSearchResult(undefined);
        }}
        shouldRenderSuggestions={(value, reason) => {
          if (reason === 'escape-pressed') {
            return false;
          } else if (value.length === 0) {
            // input is blank
            return false;
          } else if (
            reason === 'input-focused' &&
            !hasSelection &&
            value.length > 3
          ) {
            // Input is not blank, and the input is not a valid cadaster
            // address.
            return true;
          } else if (reason === 'input-focused' || reason === 'input-blurred') {
            // If blurred, don't show it.
            // If focused, but has a valid address, don't show it
            return false;
          } else {
            return true;
          }
        }}
        loading={loading}
      />
    </>
  );
};
