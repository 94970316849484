import { css } from '@emotion/react';
import {
  Autosuggest,
  type AutosuggestProps,
  BirthNumberInput,
  Label,
  PlainButton,
  TelephoneNumberInput,
  TextInput,
  type TextInputProps,
  fonts,
  space,
} from 'folio-common-components';
import {
  findLikelyEmailTypo,
  fnum,
  formatters,
  invariant,
  removeWhitespace,
  validators,
} from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import {
  EnvelopeLetterMailIcon,
  FingerprintIcon,
  HomeIcon,
  PersonIcon,
  PhoneIcon,
} from '../../icons';
import { Labels } from '../../messages';
import { useSelector } from '../../state';
import type { Person } from '../../state/editor';
import { CadasterEnabledAddress } from '../CadasterAddressInput';
import { FormRow } from '../FormRow';

export type InputItem =
  | Exclude<keyof Person, 'id' | 'address' | 'postalCode'>
  | 'autoName';

type ChangeFun = (person: Person) => void;

export interface Props {
  person: Person;
  inputs: InputItem[];
  onChange: ChangeFun;
  labelOverrides?: Partial<Record<InputItem, React.ReactNode>>;
  autoSuggestBlacklist?: string[];
  validate?: boolean;
  disableAutocomplete?: boolean;
  /**
   * `new` shows a warning on existing pNum
   */
  editType: 'edit' | 'new';
}

interface InputDef {
  key: keyof Person;
  label: React.ReactNode;
  input:
    | 'name'
    | 'pNum'
    | 'cadasterAddress'
    | 'autoSuggest'
    | 'phone'
    | 'email';
  inputProps: (Partial<TextInputProps> | Partial<AutosuggestProps<any>>) & {
    css?: ReturnType<typeof css>;
  };
}

type InputDefMap = Record<InputItem, InputDef>;

const fullWidth = css`
  width: 100%;
`;

const inputMap: InputDefMap = {
  email: {
    key: 'email',
    input: 'email',
    label: <Labels.Email />,
    inputProps: {
      autoComplete: 'email',
      icon: <EnvelopeLetterMailIcon />,
      css: fullWidth,
      type: 'email',
    },
  },

  name: {
    key: 'name',
    input: 'name',
    label: <Labels.Name />,
    inputProps: {
      autoCapitalize: 'words',
      autoComplete: 'name',
      icon: <PersonIcon />,
      css: fullWidth,
    },
  },

  autoName: {
    key: 'name',
    input: 'autoSuggest',
    label: <Labels.Name />,
    inputProps: {
      icon: <PersonIcon />,
      css: fullWidth,
      autoComplete: 'off',
      autoCapitalize: 'words',
    },
  },

  phone: {
    key: 'phone',
    input: 'phone',
    label: <Labels.Phone />,
    inputProps: {
      autoComplete: 'mobile tel-national',
      icon: <PhoneIcon />,
      css: fullWidth,
      type: 'tel',
    },
  },
  pNum: {
    key: 'pNum',
    input: 'pNum',
    label: <Labels.PNum kind="long" />,
    inputProps: {
      icon: <FingerprintIcon />,
      css: fullWidth,
    },
  },

  cadasterAddress: {
    key: 'address',
    input: 'cadasterAddress',
    label: <Labels.Address />,
    inputProps: {
      autoComplete: 'street-address',
      icon: <HomeIcon />,
      css: fullWidth,
    },
  },
};

interface InputProps {
  def: InputDef;
  onChange: (p: Partial<Person>) => void;
  person: Person;
  people?: Person[];
  autoSuggestBlacklist: string[];
  validate?: boolean;
  disableAutocomplete: boolean;
  label: React.ReactNode;
  editType: Props['editType'];
}

function isConflictingPerson(
  person: Person,
  people: Person[] | undefined,
): boolean {
  if (people === undefined || person.pNum === '') {
    return false;
  }
  const extantPerson = people.find(p => p.pNum === person.pNum);
  return (
    extantPerson !== undefined &&
    extantPerson.name !== '' &&
    extantPerson.name !== person.name
  );
}

export function isValidCadasterAddress(cadasterAddress: string | undefined) {
  return cadasterAddress != null && cadasterAddress.trim() !== '';
}

const InputEle: React.FC<InputProps> = props => {
  const {
    def,
    onChange,
    person,
    people,
    validate,
    autoSuggestBlacklist,
    disableAutocomplete,
    label,
    editType,
  } = props;

  let { input, inputProps, key } = def;

  if (disableAutocomplete) {
    inputProps = {
      ...inputProps,
      autoComplete: 'turn-it-off',
    };
  }

  const changer = val => {
    onChange({ [key]: val });
  };

  switch (input) {
    case 'name': {
      const invalid = validate && !validators.isValidName(person.name);
      const value = person[key];
      // Only possible for the cadasterAddress, where `undefined` has semantic
      // information
      invariant(typeof value === 'string');

      return (
        <TextInput
          {...inputProps}
          value={value}
          label={label}
          onChange={changer}
          message={
            invalid
              ? { kind: 'error', content: 'Vi trenger fornavn og etternavn' }
              : undefined
          }
        />
      );
    }

    case 'pNum': {
      const invalid = validate && !validators.isValidPnum(person.pNum);
      const isNot18 = validate && !fnum.isAtLeast18(person.pNum);
      const conflictingPerson =
        editType === 'new' && isConflictingPerson(person, people);
      const cleaned = removeWhitespace(person.pNum);
      const looksLikeOrgNum =
        cleaned.length === 9 &&
        (cleaned.startsWith('8') || cleaned.startsWith('9'));
      const errorMessage = looksLikeOrgNum
        ? 'Oppgi ditt fødselsnummer her. Vi spør om eiere senere.'
        : conflictingPerson
        ? 'Det finnes allerede en person med dette fødselsnummeret'
        : invalid
        ? 'Ugyldig fødselsnummer'
        : isNot18
        ? 'Man må være over 18 år for å være med å stifte AS'
        : undefined;

      const value = person[key];
      // Only possible for the cadasterAddress, where `undefined` has semantic
      // information
      invariant(typeof value === 'string');

      return (
        <BirthNumberInput
          {...inputProps}
          value={value}
          label={label}
          onChange={changer}
          message={
            errorMessage ? { kind: 'error', content: errorMessage } : undefined
          }
        />
      );
    }

    case 'phone': {
      const invalid = validate && !validators.isValidMobileNumber(person.phone);
      return (
        <TelephoneNumberInput
          {...inputProps}
          label={label}
          onChange={changer}
          value={person.phone}
          message={
            invalid
              ? { kind: 'error', content: 'Vi trenger et gyldig mobilnummer' }
              : undefined
          }
        />
      );
    }

    case 'email': {
      const invalid = validate && !validators.isValidEmail(person.email);
      const likelyCorrectEmail = findLikelyEmailTypo(person.email);
      return (
        <>
          <TextInput
            {...inputProps}
            label={label}
            onChange={changer}
            value={person.email}
            message={
              invalid ? { kind: 'error', content: 'Ugyldig e-post' } : undefined
            }
          />
          {likelyCorrectEmail && !invalid ? (
            <PlainButton
              css={css`
                ${space([4], 'margin-vertical')};
                ${fonts.font100book};
                color: ${colors.blue};

                :hover {
                  text-decoration: underline;
                }
              `}
              onClick={() => changer(likelyCorrectEmail.email)}
            >
              Mente du {likelyCorrectEmail.domain}?
            </PlainButton>
          ) : null}
        </>
      );
    }

    case 'autoSuggest': {
      const p = (people || [])
        .filter(e => e.name !== '')
        .filter(e => !autoSuggestBlacklist.includes(e.id))
        .filter(e =>
          e.name.toLowerCase().startsWith(person.name.toLowerCase()),
        );
      return (
        <div>
          <Label for="autoName">{label}</Label>
          <Autosuggest<Person>
            icon={inputProps.icon}
            suggestions={p}
            getSuggestionValue={p => p.name}
            renderSuggestion={renderSuggestion}
            inputProps={{
              value: person.name,
              id: 'autoName',
              onChange: (_, item) => {
                if (item) {
                  changer(item.newValue);
                }
              },
              css: inputProps.css,
              autoComplete: 'off',
              // fixme: copy over stuff from def.inputProps to here
            }}
            onSuggestionsFetchRequested={() => undefined}
            onSuggestionsClearRequested={() => undefined}
            onSuggestionSelected={(_, sel) => {
              onChange(sel.suggestion);
            }}
          />
        </div>
      );
    }

    case 'cadasterAddress': {
      const invalid =
        validate && !isValidCadasterAddress(person.cadasterAddress);

      return (
        <div
          css={
            invalid
              ? // Copy of textLikeInputError
                // FIXME: post-dse fix this properly by adding a message prop
                // to AutoSuggest
                css`
                  [role='listbox'] {
                    border-color: ${colors.wcagRed};
                  }

                  input {
                    border-color: ${colors.wcagRed};

                    &:focus {
                      border-color: ${colors.wcagRed};
                      box-shadow: inset 0 0 0 1px ${colors.wcagRed};
                    }
                  }
                `
              : null
          }
        >
          <CadasterEnabledAddress
            // Using pnum as the key forces to component to rerender completely
            // when selecting an existing user. Without it, the cadaster
            // address field, which is not a "controlled component", will not
            // update the value to the pre-existing users address
            key={person.pNum}
            initialAddress={person.cadasterAddress ?? person.address}
            hasValidCadasterAddress={person.cadasterAddress != null}
            icon={inputProps.icon}
            label={label}
            onSelectionChange={e => {
              if (e === undefined) {
                onChange({ cadasterAddress: undefined });
              } else {
                onChange({
                  cadasterAddress: `${e.addressText}, ${e.postalCode} ${e.postalPlace}`,
                  address: e.addressText,
                  postalCode: e.postalCode,
                });
              }
            }}
          />
          {invalid ? (
            <div
              css={css`
                margin: 4px 0;
                ${fonts.font100book};
                color: ${colors.wcagRed};
              `}
            >
              Du trenger en adresse i Norge
            </div>
          ) : null}
        </div>
      );
    }
  }
};

function renderSuggestion(person: Person) {
  return (
    <div>
      <div>
        <b>{person.name}</b>
      </div>
      <div css={fonts.font100book}>
        {formatters.formatBirthNumber(person.pNum)}
      </div>
    </div>
  );
}

interface InputRowProps {
  def: InputDef;
  onChange: (p: Partial<Person>) => void;
  person: Person;
  autoSuggestBlacklist: string[];
  label: React.ReactNode;
  people?: Person[];
  validate?: boolean;
  disableAutocomplete: boolean;
  editType: Props['editType'];
}

const InputRow: React.FC<InputRowProps> = props => {
  return (
    <FormRow data-testid="FormRow">
      <InputEle {...props} />
    </FormRow>
  );
};

export const PersonInput: React.FC<Props> = props => {
  const people = useSelector(e => Object.values(e.editor.people));
  const {
    inputs,
    onChange,
    person,
    autoSuggestBlacklist = [],
    labelOverrides,
    validate,
    disableAutocomplete = false,
    editType,
  } = props;

  const changer = (p: Partial<Person>) => {
    onChange({ ...person, ...p });
  };
  return (
    <>
      {inputs.map(input => {
        const def = inputMap[input];
        const label = labelOverrides
          ? labelOverrides[def.key] || def.label
          : def.label;
        return (
          <InputRow
            key={def.inputProps.id || def.key}
            def={def}
            label={label}
            onChange={changer}
            person={person}
            people={people}
            autoSuggestBlacklist={autoSuggestBlacklist}
            validate={validate}
            disableAutocomplete={disableAutocomplete}
            editType={editType}
          />
        );
      })}
    </>
  );
};
