import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { links, mq, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { Grid } from '../Grid';
import { Page } from '../Page';

const StyledFooter = styled.footer`
  /* Some extra space on the bottom, so that the text is not too close to the edge on e.g. iPhone X */
  padding-bottom: 4rem;
  margin-top: 6rem;
  background: ${colors.background};

  @media (max-width: 880px) {
    margin-top: 4rem;
  }
`;

const SmallFooter = styled.div`
  padding: 2rem 0;
`;

const SmallGrid = styled.div`
  padding: 0 2rem;
`;

const SmallFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${space([16], 'gap')};

  @media not all and ${mq.extraLarge} {
    grid-template-columns: none;
  }
`;

const FooterContent: React.FC = () => {
  return (
    <SmallFooterGrid>
      <div
        css={css`
          display: grid;
          row-gap: 32px;
          column-gap: 16px;
          grid-template: 'made-by' 'privacy';

          @media (min-width: 1024px) {
            grid-template: 'made-by privacy';
            grid-template-columns: 1fr max-content max-content;
          }
        `}
      >
        <div
          css={css`
            grid-area: made-by;
            text-wrap: balance;
          `}
        >
          En tjeneste fra{' '}
          <a
            href="https://folio.no"
            css={css`
              ${links.blackLink};
            `}
          >
            Folio
          </a>{' '}
          og{' '}
          <a
            href="https://www.brreg.no"
            css={css`
              ${links.blackLink};
            `}
          >
            Brønnøysundregistrene
          </a>
          .
        </div>

        <div
          css={css`
            grid-area: privacy;
          `}
        >
          <a
            href="https://folio.no/personvern"
            css={css`
              ${links.blackLink}
            `}
            target="_blank"
          >
            Personvern&shy;erklæring & cookies
          </a>
        </div>
      </div>
    </SmallFooterGrid>
  );
};

export const Footer: React.FC = () => {
  const content = <FooterContent />;
  const smallish = useMediaLayout('(max-width: 880px)');

  return (
    <StyledFooter>
      <Page>
        <SmallFooter>
          {smallish ? <Grid>{content}</Grid> : <SmallGrid>{content}</SmallGrid>}
        </SmallFooter>
      </Page>
    </StyledFooter>
  );
};
