import { css } from '@emotion/react';
import { fonts, mq, shadows, space } from 'folio-common-components';
import * as React from 'react';
import { CookieCreamIllustration } from '../illustrations';
import { Button } from './buttons';
import { zIndex } from './z-index';

function hasConsentValue() {
  return document.cookie
    .split(';')
    .map(value => value.trim())
    .some(cookie => cookie === 'consent=0' || cookie === 'consent=1');
}

export const CookieBanner: React.FC = () => {
  const [visible, setVisible] = React.useState(!hasConsentValue());

  function onConsentNotGranted() {
    setVisible(false);
    document.cookie = `consent=0;path=/;max-age=${60 * 60 * 24 * 365 * 5}`;
    fetch('/consent');
  }

  function onConsentGranted() {
    setVisible(false);
    document.cookie = `consent=1;path=/;max-age=${60 * 60 * 24 * 365 * 5}`;
    fetch('/consent');

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // We need to use `arguments` in gtag()
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }
    // @ts-expect-error: We need to use `arguments` in gtag()
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
    window.dataLayer.push({ event: 'consent-granted' });
  }

  if (!visible) {
    return null;
  }

  return (
    <div
      id="cookie-banner"
      css={css`
        position: fixed;
        bottom: 0;
        z-index: ${zIndex.cookieBanner};
        display: grid;
        justify-content: center;
        width: 100%;
        pointer-events: none;
      `}
    >
      <div
        css={css`
          pointer-events: all;
          box-shadow: ${shadows.mediumShadow};
          background: #fff;
          border-radius: var(--border-radius);
          display: grid;
          align-items: center;
          justify-self: center;
          ${space([16], 'gap', 'padding')};
          ${space([16, 32], 'margin')};

          @media ${mq.large} {
            grid-template-columns: max-content 1fr 260px;
          }
        `}
      >
        <CookieCreamIllustration />
        <div>
          <div
            css={css`
              ${fonts.font400bold};
            `}
          >
            Men først, cookies!
          </div>
          <div
            css={css`
              ${space([4], 'margin-top')};
              ${fonts.font200book};
            `}
          >
            Vi bruker cookies til å samle data og treffe bedre med skreddersydde
            annonser.{' '}
            <a
              href="https://folio.no/personvern#cookies"
              css={css`
                white-space: nowrap;
              `}
            >
              Mer om cookies
            </a>
            .
          </div>
        </div>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            ${space([8], 'gap')};
            ${space([24], 'padding-horizontal')};
          `}
        >
          <Button
            data-no-consent
            level="secondary"
            onClick={() => onConsentNotGranted()}
          >
            Blokker
          </Button>
          <Button data-consent onClick={() => onConsentGranted()}>
            Godta
          </Button>
        </div>
      </div>
    </div>
  );
};
