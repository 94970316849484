import styled from '@emotion/styled';
import { Logo, mq } from 'folio-common-components';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { pageMap } from '../../paths';
import { Grid } from '../Grid';
import { Page } from '../Page';
import { TopLevelMenuSmall } from '../TopLevelMenu';
import { HeaderBar, StyledLink } from './shared';
import type { Props } from './index';

const StyledGrid = styled(Grid)`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-rows: 40px;
  padding: 1em 0;
`;

const Heading = styled.h1`
  margin: 0px;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const NavigationWrapper = styled.div`
  margin-left: auto;
`;

const StyledLogo = styled(Logo)`
  display: block;
  margin: 0 10px 0 0;
`;

export const SmallHeaderBar: React.FC<Props> = ({
  className,
  menuItems,
  navigationVisible,
}) => {
  const isLarge = useMediaLayout(mq.medium);
  const isSmallWithNavigation = !isLarge && navigationVisible;
  const title = isSmallWithNavigation ? null : 'Stiftemaskinen';

  return (
    <HeaderBar className={className}>
      <Page>
        <StyledGrid>
          <Heading>
            <StyledLink
              to={{ pathname: pageMap.home, search: window.location.search }}
            >
              <StyledLogo />
              {title}
            </StyledLink>
          </Heading>
          {navigationVisible && (
            <NavigationWrapper>
              <TopLevelMenuSmall menuItems={menuItems} />
            </NavigationWrapper>
          )}
        </StyledGrid>
      </Page>
    </HeaderBar>
  );
};
